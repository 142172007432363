import classNames from "classnames";
import moment from "moment";
import React, { Component, FC, Fragment, ReactElement } from "react";
import { asyncModal } from "../../../helpers/asyncModal";
import { IList } from "../../Body";
import { Modal } from "../../modalComponents";

import styles from "./index.module.scss";
import { Button } from "ui-kit-euroopt";

interface IFieldModal {
  close: () => void;
  title: string;
  list: IList[];
  idx: number;
}

interface IState {
  activeIdx: number;
  date?: string;
  active?: boolean;
  openList: {
    [x: string]: boolean;
  };
}

interface ICard {
  name: string;
  info: string;
}

const Card: FC<ICard> = ({ name, info }) => {
  return (
    <div className={styles.card}>
      <span className={styles.card__name}>{name}</span>
      <span className={styles.card__info}>{info}</span>
    </div>
  );
};

class FieldModal extends Component<IFieldModal, IState> {
  constructor(props: IFieldModal) {
    super(props);
    this.state = {
      activeIdx: this.props.idx,
      openList: {},
    };
  }
  listHeight = 260;
  fieldRef = React.createRef<HTMLDivElement>();

  apply = (): void => {
    this.props.close();
  };

  scrollTo = (id: number) => {
    const element = document.getElementById(`filter_${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  componentDidMount() {
    this.scrollTo(this.state.activeIdx);
  }

  renderWinner = (id: number, name: string) => {
    const { list } = this.props;
    const { activeIdx, openList } = this.state;

    const curList = list[activeIdx - 1].Winners;
    const filterCurList = curList.filter((el) => el.PrizeType === id);
    const index = `${id}_${activeIdx}`;
    return (
      <>
        {filterCurList
          .filter((el, idx) =>
            filterCurList.length > 4
              ? idx > 4 && !openList[index]
                ? false
                : true
              : true
          )
          .map((el, idx) => (
            <>
              {idx === 0 && <span className={styles.titleText}>{name}</span>}
              <Card name={el.Winner} info={el.GamePrizeDescription} />
            </>
          ))}
        {!openList[index] && filterCurList.length > 4 ? (
          <Button
            mix={styles.button}
            onClick={() => {
              const copy = JSON.parse(JSON.stringify(openList));
              copy[index] = true;

              this.setState({ openList: copy });
            }}
          >
            Смотреть все
          </Button>
        ) : null}
      </>
    );
  };

  render(): ReactElement {
    const { close, list } = this.props;

    return (
      <Fragment>
        <Modal.Swiper onSwipeBottom={close} />
        <Modal.Close onClose={close} />
        <h3 className={styles.title}>Розыгрыши и победители</h3>

        <div className={styles.field} ref={this.fieldRef}>
          <div className={styles.tabs}>
            {list.map((el, idx) => (
              <div
                key={idx}
                id={`filter_${idx + 1}`}
                onClick={() =>
                  this.setState({ activeIdx: idx + 1 }, () => {
                    this.scrollTo(idx + 1);
                  })
                }
                className={classNames(
                  styles.tabs__tab,
                  this.state.activeIdx === idx + 1 && styles.tabs__tab__active
                )}
              >
                <span>
                  {list.length - 1 === idx
                    ? "Главный розыгрыш"
                    : moment(el.DrawDate).format("DD.MM")}
                </span>
              </div>
            ))}
          </div>
          <div className={styles.wrapper}>
            {list[this.state.activeIdx - 1].Winners.length ? (
              <>
                {this.renderWinner(6, "Денежные призы")}
                {this.renderWinner(1, "Сертификаты")}
                {(this.state.activeIdx === 1 || this.state.activeIdx === 2) && this.renderWinner(8, this.state.activeIdx === 1 ? "5 телевизоров Artel" : "Пылесосы Artel")}
              </>
            ) : (
              <span className={styles.wrapper__null}>
                Ожидается розыгрыш
                <br />
                <br />
                {this.state.activeIdx === list.length ? (
                  "Денежный приз 50 000 р."
                ) : (
                  <>
                    Денежный приз 5 000 р.
                    <br />
                    Сертификат на 500 р.
                    <br />
                    50 сертификатов на 50 р.
                    <br />
                    {this.state.activeIdx === 1 && "5 телевизоров Artel"}
                    {this.state.activeIdx === 2 && "5 пылесосов Artel"}
                  </>
                )}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export const openFieldModal = (list: IList[], idx: number): Promise<void> =>
  asyncModal(
    FieldModal,
    { list, idx },
    {
      closeOnRouteChange: false,
      classNames: {
        modal: classNames("swiping", styles.modal_field),
      },
      showCloseIcon: false,
      focusTrapped: false,
    }
  );
