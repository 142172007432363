import cls from "../Footer/index.module.scss";

const Footer = () => {
  return (
    <footer id="footer" className={cls.footer}>
      <div className={cls.footer__copyright} dangerouslySetInnerHTML={{ __html: `Рекламная игра Emall.by №2 Срок проведения с 27.07.2023 по 27.10.2023 (включая период выдачи призов и публикации результатов). Свидетельство о регистрации №4338 от 17.07.2023 года, выданное Министерством антимонопольного регулирования и торговли Республики Беларусь. ООО «ЭфСиБи Бел», УНП 193185741` }} />
    </footer>
  );
};
export default Footer;
