import clsx from "clsx";
import moment from "moment";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Expander, Icon } from "ui-kit-euroopt";
import { request } from "../../helpers/request";
import { Adaptive } from "../adaptive";
import { Arrow } from "../arrow";
import cls from "../Body/index.module.scss";
import { Container } from "../container";
import { ContainerFluid } from "../container-fluid";
import { openFieldModal } from "../modal/filter";

interface IBlock {
  name: string;
  children?: string | JSX.Element | JSX.Element[];
  mix?: string;
  id?: string;
}

const Block: FC<IBlock> = ({ name, children, mix, id }) => {
  return (
    <section id={id} className={clsx(cls.block, mix)}>
      <h2 className={cls.block__name}>{name}</h2>
      <div className={cls.block__body}>{children}</div>
    </section>
  );
};

interface IDrawsAndWinnersCard {
  idx: number;
  date: string;
  list: IList[];
  superDraws?: boolean;
  winner?: boolean;
}

const DrawsAndWinnersCard: FC<IDrawsAndWinnersCard> = ({
  idx,
  date,
  superDraws,
  winner,
  list,
}) => {
  return (
    <div
      className={clsx(
        cls.card,
        superDraws && cls.card__superDraws,
        winner && cls.card__winner
      )}
    >
      <div className={cls.card__title}>
        <span className={cls.card__title__left}>
          {superDraws ? "Главный розыгрыш" : `${idx} неделя`}
        </span>
        {winner ? (
          <span
            className={cls.card__title__rigth__blue}
            onClick={() => openFieldModal(list, idx)}
          >
            Результаты <Icon name="chevron-12" />
          </span>
        ) : (
          <span className={cls.card__title__rigth}>{date}</span>
        )}
      </div>
      <div className={cls.card__body}>
        {!superDraws && (
          <>
            {" "}
            <span>Денежный приз 5 000 р.</span>
            <span>Сертификат на 500 р.</span>
            <span>50 сертификатов на 50 р.</span>
          </>
        )}
        {idx === 1 && <span>5 телевизоров Artel</span>}
        {idx === 2 && <span>5 пылесосов Artel</span>}
        {superDraws && (
          <span className={cls.card__body__super}>Денежный приз 50 000 р.</span>
        )}
      </div>
    </div>
  );
};

interface IDrawsAndWinners {
  list: IList[];
}

const DrawsAndWinners: FC<IDrawsAndWinners> = ({ list }) => {
  const [arrowsIsVisible, setArrowsIsVisible] = useState<{
    left: boolean;
    right: boolean;
  }>({ left: false, right: false });
  const wrapperSingleRef = useRef<null | HTMLDivElement>(null);

  const checkArrowIsVisible = (double = false): void => {
    const element = wrapperSingleRef.current;
    if (element) {
      if (element.scrollWidth) {
        setArrowsIsVisible({
          left: !!element.scrollLeft,
          right:
            element.scrollWidth - element.offsetWidth !== element.scrollLeft,
        });
        if (double) {
          setTimeout(() => {
            checkArrowIsVisible();
          }, 300);
        }
      }
    }
  };

  const scrollTo = (direction: "left" | "right"): void => {
    const element = wrapperSingleRef.current;

    if (element) {
      const { scrollLeft, children } = element;
      const { width } = children[0].getBoundingClientRect();
      const widthWithPadding = (width + 16) * 3;
      const position =
        direction === "right"
          ? scrollLeft + widthWithPadding
          : scrollLeft - widthWithPadding;
      element.scroll({ left: position, behavior: "smooth" });
      setTimeout(() => checkArrowIsVisible(true), 300);
    }
  };

  useEffect(() => {
    setTimeout(checkArrowIsVisible, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperSingleRef.current]);

  const arr = [
    {
      DrawDate: "08.04.2023",
      Winners: [],
    },
    {
      DrawDate: "08.11.2023",
      Winners: [],
    },
    {
      DrawDate: "08.18.2023",
      Winners: [],
    },
    {
      DrawDate: "08.25.2023",
      Winners: [],
    },
    {
      DrawDate: "09.01.2023",
      Winners: [],
    },
    {
      DrawDate: "09.08.2023",
      Winners: [],
    },
    {
      DrawDate: "09.15.2023",
      Winners: [],
    },
    {
      DrawDate: "09.22.2023",
      Winners: [],
    },
    {
      DrawDate: "09.22.2023",
      Winners: [],
    },
  ];

  const Logos: FC = () => {
    return (
      <Fragment>
        {(list.length ? list : arr).map((el, idx) => (
          <DrawsAndWinnersCard
            date={moment(el.DrawDate).format("DD.MM")}
            superDraws={idx + 1 === (list.length ? list.length : arr.length)}
            winner={!!el.Winners.length}
            idx={idx + 1}
            key={idx}
            list={list.length ? list : arr}
          />
        ))}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Adaptive.Desktop>
        <Container mix={cls.container}>
          {arrowsIsVisible.left ? (
            <Arrow
              onClick={() => scrollTo("left")}
              size="medium"
              direction="left"
              mix={cls.arrow}
            />
          ) : null}
          <div ref={wrapperSingleRef} className={cls.wrapper_single}>
            <Logos />
          </div>
          {arrowsIsVisible.right ? (
            <Arrow
              size="medium"
              onClick={() => scrollTo("right")}
              direction="right"
              mix={clsx(cls.arrow, cls.arrow_right)}
            />
          ) : null}
        </Container>
      </Adaptive.Desktop>
      <Adaptive.Mobile>
        <ContainerFluid mix={cls.wrapper_single}>
          <Logos />
        </ContainerFluid>
      </Adaptive.Mobile>
    </Fragment>
  );
};

const faq = [
  {
    title: "Какие даты проведения рекламной игры?",
    desc: `Рекламная игра проходит с 27 июля по 20 сентября. Обратите внимание, что заказ с игровыми кодами, оформленный в последний день игры, должен быть получен в этот же день. По заказам, оформленным в период действия игры, но полученным после ее завершения, коды не начисляются`,
  },
  {
    title: "Как стать участником рекламной игры?",
    desc: `Для участия необходимо совершить покупку товаров-везунчиков на сумму от 2 р., а также подтвердить участие в <a href="https://emall.by/profile?ServiceId=27" target="_blank">личном кабинете</a> в период с 27 июля 2023 года 00:00:00 по 20 сентября 2023 года 23:59:59`,
  },
  {
    title: "Как начисляются коды?",
    desc: `<ul>
    <li>За каждые 2 р. стоимости товаров-везунчиков</li>
    <li>В течение 24 часов после получения заказа (обратите внимание – не после оформления заказа, а после получения)</li>
    </ul>
    </br>
    
    Проверить игровые коды можно в <a href="https://emall.by/advertising-game" target="_blank">личном кабинете</a>`,
  },
  {
    title: "Какие товары необходимо купить для участия в рекламной игре?",
    desc: `Для участия в рекламной игре необходимо купить любой товар-везунчик на сумму от 2 р. Со всеми товарами, участвующими в рекламной игре, можно ознакомиться <a href="https://emall.by/actions/lucky-goods" target="_blank">здесь</a>`,
  },
  {
    title: "Как узнать сколько у меня игровых кодов?",
    desc: `Свои игровые коды можно посмотреть в <a href="https://emall.by/advertising-game" target="_blank">личном кабинете</a> в разделе Игровые коды`,
  },
  {
    title: "Что делать, если мне не приходит код?",
    desc: `Проверьте:
    </br>
    </br>
    <ul>
  <li>Подтверждено ли согласие на участие в рекламной игре;</li>
  <li>Правильно ли указаны персональные данные в личном кабинете;</li>
  <li>Есть ли в чеке товары-везунчики на сумму от 2 р.;</li>
  <li>Если все пункты соблюдены, но игровой код все равно не начислен, обращайтесь на нашу инфолинию по телефону: <a href="tel:+375297710991" target="_blank">+375 29 771 09 91</a> (МТС, A1, life:)), 8:30-20:30, пн-вс</li>
  </ul>`,
  },
  {
    title: "Когда и где проходят розыгрыши?",
    desc: `Розыгрыши будут проходить по адресу г. Минск, пер. Козлова, 25, пом. 22 (7 этаж).</br>
    С графиком проведения розыгрышей можно ознакомиться <span id="scroll">здесь</span>`,
  },
  {
    title: "Как я узнаю, что я стал победителем?",
    desc: `В течение 2 рабочих дней с вами свяжутся организаторы рекламной игры. Также результаты розыгрыша будут опубликованы на сайте igra2.emall.by и отобразятся в вашем <a href="https://emall.by/advertising-game" target="_blank">личном кабинете</a>`,
  },
  {
    title: "Где и когда можно получить приз?",
    desc: `Полная информация о получении приза указана в п.12 <a href="/assets/files/file2.pdf" target="_blank">правил рекламной игры</a>`,
  },
  {
    title: "Не нашли ответ на свой вопрос?",
    desc: `Задайте его по телефону <a href="tel:+375297710991" target="_blank">+375 29 771 09 91</a> (МТС, A1, life:)). Режим работы: 8:30 - 20:30, понедельник-воскресенье`,
  },
];

const Prizes = () => {
  const arr = [
    {
      icon: "assets/images/desk_1.svg?v=2",
      iconMobile: "assets/images/mob_1.svg?v=2",
      title: "50 000 р.",
      desc: "Главный приз",
    },
    {
      icon: "assets/images/desk_2.svg?v=2",
      iconMobile: "assets/images/mob_2.svg?v=2",
      title: "5 000 р.",
      desc: "Денежный приз",
    },
    {
      icon: "assets/images/desk_2.svg?v=2",
      iconMobile: "assets/images/mob_2.svg?v=2",
      title: "500 р.",
      desc: "Сертификат emall",
    },
    {
      icon: "assets/images/desk_3.svg?v=2",
      iconMobile: "assets/images/mob_3.svg?v=2",
      title: "50 р.",
      desc: "Сертификат emall",
    },
  ];
  return (
    <>
      <div className={cls.prizes}>
        {arr.map((el) => (
          <div className={cls.prizes__card} key={el.title}>
            <img className={cls.prizes__icon} src={el.icon} alt="" />
            <span className={cls.prizes__title}>{el.title}</span>
            <span className={cls.prizes__desc}>{el.desc}</span>
          </div>
        ))}
      </div>
      <div className={cls.prizesTitle}>
        <img
          className={cls.prizesTitle__icon}
          src="assets/images/cat.png"
          alt=""
        />
        <span className={cls.prizesTitle__text}>+ 10 специальных призов</span>
      </div>
    </>
  );
};

const Brands = () => {
  const arr = [
    {
      url: "https://emall.by/actions/lucky-goods?cid=16217",
      icon: "assets/images/brands/1.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16207",
      icon: "assets/images/brands/2.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16220",
      icon: "assets/images/brands/3.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17069",
      icon: "assets/images/brands/4.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16219",
      icon: "assets/images/brands/5.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17073",
      icon: "assets/images/brands/6.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17077",
      icon: "assets/images/brands/7.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16206",
      icon: "assets/images/brands/8.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16213",
      icon: "assets/images/brands/9.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16210",
      icon: "assets/images/brands/10.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17072",
      icon: "assets/images/brands/11.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16208",
      icon: "assets/images/brands/12.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16216",
      icon: "assets/images/brands/13.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16211",
      icon: "assets/images/brands/14.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17070",
      icon: "assets/images/brands/15.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17078",
      icon: "assets/images/brands/16.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16212",
      icon: "assets/images/brands/17.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16209",
      icon: "assets/images/brands/18.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16214",
      icon: "assets/images/brands/19.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17074",
      icon: "assets/images/brands/20.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16266",
      icon: "assets/images/brands/21.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17075",
      icon: "assets/images/brands/22.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17076",
      icon: "assets/images/brands/23.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=16267",
      icon: "assets/images/brands/24.svg?v=2",
    },
    {
      url: "https://emall.by/actions/lucky-goods?cid=17071",
      icon: "assets/images/brands/25.svg?v=2",
    },
  ];
  return (
    <div className={cls.brands}>
      <div className={cls.brands__block}>
        {arr.map((el) => (
          <a key={el.icon} target="_blank" href={el.url} rel="noreferrer">
            <div className={cls.brands__card}>
              <img src={el.icon} alt="" />
            </div>
          </a>
        ))}
      </div>
      <a
        className={cls.brands__link}
        target="_blank"
        href="https://emall.by/actions/lucky-goods "
        rel="noreferrer"
      >
        <span>Смотреть все товары</span>
      </a>
    </div>
  );
};

const Faq: FC<{
  expendValue: boolean;
  setExpendValue: (value: boolean) => void;
}> = ({ expendValue, setExpendValue }) => {
  const [state, setState] = useState(false);
  const [expend, setExpend] = useState<{ [x: string]: boolean }>({});
  function scrollTo() {
    const element = document.getElementById("draws");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  useEffect(() => {
    const element = document.getElementById("scroll");

    if (element) {
      element.addEventListener("click", scrollTo);
    }
    return () => {
      if (element) {
        element.removeEventListener("click", scrollTo);
      }
    };
  }, [state]);
  useEffect(() => {
    if (expendValue && !expend[2]) {
      setExpend((prev) => {
        const copy = JSON.parse(JSON.stringify(prev));
        copy[2] = true;
        return copy;
      });
      setExpendValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expendValue, expend]);
  return (
    <Fragment>
      {faq.map((el, idx) => (
        <div
          key={el.title}
          id={`expander-${idx}`}
          onClick={() => setState((prev) => !prev)}
        >
          <Expander
            defaultExpand={false}
            value={!!expend[idx]}
            onChange={(e) =>
              setExpend((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                copy[idx] = e;
                return copy;
              })
            }
            title={el.title}
            mix={cls.expander}
          >
            <div
              className={cls.expander__desc}
              dangerouslySetInnerHTML={{ __html: el.desc }}
            />
          </Expander>
        </div>
      ))}
    </Fragment>
  );
};

const Rules: FC<{ setExpend: (value: boolean) => void }> = ({ setExpend }) => {
  const arr = [
    {
      title: "Подтверждайте участие",
      desc: (
        <>
          Подтвердите участие в рекламной игре в личном кабинете, проставив{" "}
          <a
            href="https://emall.by/profile?ServiceId=27"
            target="_blank"
            rel="noreferrer"
          >
            согласие
          </a>
        </>
      ),
    },
    {
      title: "Покупайте товары-везунчики",
      desc: (
        <>
         Получайте игровые коды за каждые 2 р. стоимости товаров-везунчиков. {" "}
          <span onClick={scrollTo}>Подробнее</span>
        </>
      ),
    },
    {
      title: "Участвуйте в розыгрышах",
      desc: "Розыгрыши проводятся каждую неделю, главный приз 50 000 р.",
    },
  ];
  function scrollTo() {
    const element = document.getElementById("brands");
    if (element) {
      // setExpend(true);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <div className={cls.rules}>
      <div className={cls.rules__block}>
        {arr.map((el, idx) => (
          <div className={cls.rules__card} key={el.title}>
            <div className={cls.rules__card__badge} data-number={idx + 1} />
            <span className={cls.rules__card__title}>{el.title}</span>
            <span className={cls.rules__card__desc}>{el.desc}</span>
          </div>
        ))}
      </div>
      <a
        className={cls.rules__link}
        target="_blank"
        href="/assets/files/file2.pdf"
      >
        <Icon size={20} name="download" />
        <span>Скачать правила</span>
      </a>
    </div>
  );
};

interface IWinners {
  PrizeType: 1 | 6 | 8;
  Winner: string;
  GamePrizeDescription: string;
}

export interface IList {
  DrawDate: string;
  Winners: IWinners[];
}

const Body = () => {
  const [expend, setExpend] = useState(false);
  const [list, setList] = useState<IList[]>([]);
  useEffect(() => {
    request
      .post<IList[]>("Game.EcommerceGameWinnersGet", {
        GameGroupId: "43", //38 69
        RoundNumber: "1",
      })
      .then((e) => {
        setList(e ?? []);
      })
      .catch((e) => console.log("err", e));
  }, []);

  return (
    <div id="body" className={clsx(cls.body)}>
      <Block name="Правила игры">
        <Rules setExpend={setExpend} />
      </Block>
      <Block name="Призы" mix={cls.noneOverflow}>
        <Prizes />
      </Block>
      <Block mix={cls.padding} id="draws" name="Розыгрыши и победители">
        <DrawsAndWinners list={list} />
      </Block>
      <Block mix={cls.padding} id="brands" name="Бренды">
        <Brands />
      </Block>
      <Block mix={cls.expanderWrap} name="Вопросы">
        <Faq expendValue={expend} setExpendValue={setExpend} />
      </Block>
    </div>
  );
};

export default Body;
